/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { Flex, Box, Image, jsx } from "theme-ui"
import BadgeLabel from "../badge-label/badge-label"

const Hero = ({
  heroWide,
  bkImgDesktop,
  bkImgMobile,
  bkColorLeft,
  bkColorRight,
  wayfinderBadge,
  heroCaption,
  children,
}) => {
  return (
    <>
      <div
        sx={{
          mx: "auto",
          maxWidth: heroWide ? "100%" : "contentWidth",
          // eslint-disable-next-line prettier/prettier
          // borderTop: ["3px solid red", "3px solid orange", "3px solid green", "3px solid blue", "3px solid magenta"],
          background: heroWide
            ? [
                (theme) => `${theme.colors.goreLightestGray}`,
                null,
                `linear-gradient(to right, ${bkColorLeft} 0%, ${bkColorLeft} 50%, ${bkColorRight} 50%, ${bkColorRight} 100%)`,
              ]
            : [
                (theme) => `${theme.colors.goreLightestGray}`,
                null,
                bkColorLeft,
              ],
        }}
      >
        <div
          sx={{
            mx: "auto",
            maxWidth: heroWide ? "heroWidth" : "contentWidth",
            backgroundImage: [null, null, `url(${bkImgDesktop})`],
            backgroundSize: heroWide ? "contain" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right center",
            // backgroundPosition: heroWide
            //   ? [null, null, "12.5rem center", "130% center", "right center"]
            //   : "right center",
          }}
        >
          <Flex sx={{ display: ["block", null, "none"] }}>
            <Image src={bkImgMobile} />
            {heroCaption && (
              <Flex
                sx={{
                  flexWrap: "wrap",
                  maxWidth: "containerWidth",
                  mx: "auto",
                }}
              >
                <Box
                  sx={{
                    width: heroWide
                      ? ["100%", "90%", "60%"]
                      : ["100%", "100%", "50%"],
                    flex: "0 0 auto",
                    px: "defaultPadding",
                    bg: (theme) => `${theme.colors.goreLighterGray}`,
                  }}
                >
                  <p className="caption">{heroCaption}</p>
                </Box>
              </Flex>
            )}
          </Flex>
          <Flex
            sx={{
              flexWrap: "wrap",
              maxWidth: "containerWidth",
              mx: "auto",
              px: "defaultPadding",
            }}
          >
            <Box
              sx={{
                width: heroWide
                  ? ["100%", "90%", "60%"]
                  : ["100%", "100%", "50%"],
                flex: "0 0 auto",
                my: heroWide
                  ? ["defaultPadding", null, "heroWideYMargin"]
                  : ["defaultPadding", null, "heroNormalYMargin"],
                "span > *:first-child": {
                  mt: 0,
                },
                "span > *:last-child": {
                  mb: 0,
                },
                // background: "lime",
              }}
            >
              {wayfinderBadge && (
                <BadgeLabel variant="heroWayfinder">
                  {wayfinderBadge}
                </BadgeLabel>
              )}
              {children}
            </Box>
            {heroCaption && (
              <Box
                sx={{
                  width: "100%",
                  flex: "0 0 auto",
                  "span > *:first-child": {
                    mt: 0,
                  },
                  "span > *:last-child": {
                    mb: 0,
                  },
                  display: ["none", null, "block"],
                  // background: "magenta",
                }}
              >
                <p className="caption">{heroCaption}</p>
              </Box>
            )}
          </Flex>
        </div>
      </div>
    </>
  )
}

Hero.propTypes = {
  heroWide: PropTypes.bool,
  bkImgDesktop: PropTypes.string.isRequired,
  bkImgMobile: PropTypes.string.isRequired,
  bkColorLeft: PropTypes.string.isRequired,
  bkColorRight: PropTypes.string.isRequired,
  wayfinderBadge: PropTypes.string,
  heroCaption: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Hero.defaultProps = {
  heroWide: false,
  wayfinderBadge: null,
  heroCaption: null,
}

export default Hero
