/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { Box, Image, Heading, jsx } from "theme-ui"

const Tile = ({ tileHeadline, tileImage, ...props }) => {
  return (
    <>
      <Box
        sx={{
          flex: "0 0 auto",
          mb: "1rem",
        }}
        {...props}
      >
        <div sx={{ position: "relative" }}>
          <Image src={tileImage} />
          <Heading
            dangerouslySetInnerHTML={{ __html: tileHeadline }}
            as="h4"
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              m: 0,
              color: "white !important",
              px: "1rem",
              pt: "1rem",
              pb: "0.75rem",
              background: (theme) =>
                `linear-gradient(to top, ${theme.colors.goreBlackStartGradient} 0%, ${theme.colors.goreBlackEndGradient} 100%)`,
              width: "100%",
            }}
          >
            {/* {tileHeadline} */}
          </Heading>
        </div>
      </Box>
    </>
  )
}

Tile.propTypes = {
  tileHeadline: PropTypes.string.isRequired,
  tileImage: PropTypes.string.isRequired,
}

export default Tile
