/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { Badge, jsx } from "theme-ui"

const BadgeLabel = ({ variant, children }) => {
  return <Badge variant={variant}>{children}</Badge>
}

BadgeLabel.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
}

BadgeLabel.defaultProps = {
  variant: "primary",
}

export default BadgeLabel
