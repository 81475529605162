/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import { Flex, Box, Text, BaseStyles, jsx } from "theme-ui"
import { Box, Text, BaseStyles, jsx } from "theme-ui"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import MarkdownConverter from "../components/markdown-converter/markdown-converter"
import FlexRow from "../components/flex-row/flex-row"
import FlexColumn from "../components/flex-column/flex-column"
import Hero from "../components/hero/hero"
import Tile from "../components/tile/tile"
import ButtonLink from "../components/button-link/button-link"
import Modal from "../components/modal/modal"
import Carousel from "../components/carousel/carousel"

// const testBorder = {
//   border: "1px solid blue",
// }

// const testBackgroundStyles = () => {
//   return {
//     "> div:nth-of-type(3n+1)": { bg: "#eee" },
//     "> div:nth-of-type(3n+2)": { bg: "#ccc" },
//     "> div:nth-of-type(3n+3)": { bg: "#ddd" },
//     "> div *": { bg: "#eee" },
//   }
// }

// const TestBox = ({ boxWidth, ...props }) => (
//   <Box
//     p="defaultPadding"
//     sx={{
//       width: ["100%", boxWidth],
//       flex: "0 0 auto",
//     }}
//     {...props}
//   />
// )

// TestBox.propTypes = {
//   boxWidth: PropTypes.string.isRequired,
// }

const UnifiedLayoutBody = ({ contentBlocks }) => {
  return (
    <>
      {contentBlocks.map(
        ({
          blockGroupType,
          overrideYTSpacing,
          overrideYBSpacing,
          bkColor,
          anchorID,
          reverseMobileOrder,
          blockCells,
        }) => (
          <>
            {blockGroupType === "Carousel" && <Carousel frames={blockCells} />}
            {blockGroupType === "Normal Hero" && (
              <>
                {blockCells.map(
                  ({
                    bkColorLeft,
                    bkColorRight,
                    bkImgDesktop,
                    bkImgMobile,
                    wayfinderBadge,
                    heroCaption,
                    copy,
                  }) => (
                    <Hero
                      bkColorLeft={bkColorLeft}
                      bkColorRight={bkColorRight}
                      bkImgDesktop={bkImgDesktop}
                      bkImgMobile={bkImgMobile}
                      wayfinderBadge={wayfinderBadge}
                      heroCaption={heroCaption}
                    >
                      <MarkdownConverter content={copy} />
                    </Hero>
                  )
                )}
              </>
            )}
            {blockGroupType === "Wide Hero" && (
              <>
                {blockCells.map(
                  ({
                    bkColorLeft,
                    bkColorRight,
                    bkImgDesktop,
                    bkImgMobile,
                    copy,
                  }) => (
                    <Hero
                      heroWide
                      bkColorLeft={bkColorLeft}
                      bkColorRight={bkColorRight}
                      bkImgDesktop={bkImgDesktop}
                      bkImgMobile={bkImgMobile}
                    >
                      <MarkdownConverter content={copy} />
                    </Hero>
                  )
                )}
              </>
            )}
            {blockGroupType === "Normal Row" && (
              <FlexRow
                paddingYTOverride={overrideYTSpacing}
                paddingYBOverride={overrideYBSpacing}
                bkColor={bkColor}
                anchorID={anchorID}
                reverseMobileOrder={reverseMobileOrder}
              >
                {blockCells.map(
                  ({
                    blockCellType,
                    blockHeadline,
                    blockImage,
                    blockCellWidth,
                    copy,
                    ctas,
                    ctasModal,
                  }) => (
                    <FlexColumn
                      blockCellType={blockCellType}
                      cellCount={blockCells.length}
                      cellWidth={blockCellWidth}
                      marginOverride={overrideYBSpacing}
                      reverseMobileOrder={reverseMobileOrder}
                    >
                      {blockHeadline && (
                        <Tile
                          tileHeadline={blockHeadline}
                          tileImage={blockImage}
                        />
                      )}
                      <MarkdownConverter content={copy} />
                      {ctas && (
                        <Box sx={{ mt: "1.5rem" }}>
                          {ctas.map(
                            ({
                              buttonDestination,
                              buttonStyle,
                              buttonLabel,
                              buttonIcon,
                            }) => (
                              <ButtonLink
                                buttonDestination={buttonDestination}
                                buttonStyle={buttonStyle}
                                buttonIcon={buttonIcon}
                              >
                                {buttonLabel}
                              </ButtonLink>
                            )
                          )}
                        </Box>
                      )}
                      {ctasModal && (
                        <Box sx={{ mt: "1.5rem" }}>
                          {ctasModal.map(
                            ({
                              modalStyle,
                              modalLabel,
                              modalIcon,
                              modalFormURL,
                              modalWindowTitle,
                            }) => (
                              <>
                                <Modal
                                  trigger={
                                    <ButtonLink
                                      buttonStyle={modalStyle}
                                      buttonIcon={modalIcon}
                                    >
                                      {modalLabel}
                                    </ButtonLink>
                                  }
                                  modalContent={
                                    <Box sx={{ pt: "defaultPadding" }}>
                                      <Text
                                        variant="modalTitle"
                                        sx={{
                                          width: 300,
                                          mx: "auto",
                                          pt: "defaultPadding",
                                        }}
                                      >
                                        {modalWindowTitle}
                                      </Text>
                                      <iframe
                                        src={modalFormURL}
                                        title="GORE INNOVATION LABS Fine Wire"
                                        width="300"
                                        height="480"
                                        frameBorder="0"
                                        sx={{
                                          mx: "auto",
                                          pt: "defaultPadding",
                                          display: "block",
                                        }}
                                      />
                                    </Box>
                                  }
                                />
                              </>
                            )
                          )}
                        </Box>
                      )}
                    </FlexColumn>
                  )
                )}
              </FlexRow>
            )}
          </>
        )
      )}
      {/*
        BASE BOXES FOR EXPERIMENTATION
      */}
      {/*
      <Flex
        my="1.25rem"
        sx={{
          color: "goreWhite",
          flexWrap: "wrap",
          fontWeight: "black",
          // justifyContent: "center",
          maxWidth: "containerWidth",
          mx: "auto",
          // ...testBackgroundStyles(),
          // ...testBorder,
        }}
      >
        <TestBox boxWidth="50%">TEST BOX</TestBox>
        <TestBox boxWidth="25%">TEST BOX</TestBox>
        <TestBox boxWidth="25%">TEST BOX</TestBox>
        <TestBox boxWidth="25%">TEST BOX</TestBox>
        <TestBox boxWidth="25%">TEST BOX</TestBox>
        <TestBox boxWidth="100%">TEST BOX</TestBox>
      </Flex>
      */}
    </>
  )
}

UnifiedLayoutBody.propTypes = {
  contentBlocks: PropTypes.arrayOf(PropTypes.object).isRequired,
  // content: PropTypes.string.isRequired,
}

const UnifiedLayout = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout includeNav={!post.frontmatter.disableNavBar}>
      <Metadata
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        article
      />
      <BaseStyles>
        <UnifiedLayoutBody
          contentBlocks={post.frontmatter.contentBlocks}
          // content={post.html}
        />
      </BaseStyles>
    </Layout>
  )
}

UnifiedLayout.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
}

export default UnifiedLayout

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        disableNavBar
        title
        description
        path
        contentBlocks {
          blockGroup
          blockGroupType
          bkColor
          anchorID
          overrideYBSpacing
          overrideYTSpacing
          reverseMobileOrder
          blockCells {
            blockCell
            blockCellType
            bkColorLeft
            bkColorRight
            bkImgDesktop
            bkImgMobile
            blockCellWidth
            blockHeadline
            blockImage
            copy
            wayfinderBadge
            heroCaption
            ctas {
              buttonDestination
              buttonLabel
              buttonStyle
              buttonIcon
            }
            ctasModal {
              modalLabel
              modalStyle
              modalIcon
              modalFormURL
              modalWindowTitle
            }
          }
        }
      }
    }
  }
`
