/** @jsx jsx */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-unused-vars
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Box, jsx } from "theme-ui"
import Slider from "react-slick"
import Hero from "../hero/hero"
import MarkdownConverter from "../markdown-converter/markdown-converter"
import ButtonLink from "../button-link/button-link"
import "./slick.css"
import "./slick-theme.css"

// eslint-disable-next-line react/prefer-stateless-function
export default class SlickCarousel extends Component {
  render() {
    const carouselSettings = {
      arrows: false,
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
    }
    const { frames } = this.props
    return (
      <div>
        <Slider {...carouselSettings} sx={{ mb: "1rem" }}>
          {frames.map(
            ({
              bkColorLeft,
              bkColorRight,
              bkImgDesktop,
              bkImgMobile,
              copy,
              ctas,
            }) => (
              <Hero
                heroWide
                bkColorLeft={bkColorLeft}
                bkColorRight={bkColorRight}
                bkImgDesktop={bkImgDesktop}
                bkImgMobile={bkImgMobile}
              >
                <div
                  sx={{
                    zIndex: "1",
                    position: "relative",
                    height: [null, null, "21.25rem"],
                  }}
                >
                  <MarkdownConverter content={copy} />
                  {ctas && (
                    <Box sx={{ mt: "1.5rem" }}>
                      {ctas.map(
                        ({
                          buttonDestination,
                          buttonStyle,
                          buttonLabel,
                          buttonIcon,
                        }) => (
                          <ButtonLink
                            buttonDestination={buttonDestination}
                            buttonStyle={buttonStyle}
                            buttonIcon={buttonIcon}
                          >
                            {buttonLabel}
                          </ButtonLink>
                        )
                      )}
                    </Box>
                  )}
                </div>
              </Hero>
            )
          )}
        </Slider>
      </div>
    )
  }
}

SlickCarousel.propTypes = {
  frames: PropTypes.arrayOf(PropTypes.object).isRequired,
}
